import React from 'react'
import './Scale.css'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';



const marks = [
    {
      value: 10,
      label: '1',
    },
    {
      value: 20,
      label: '2',
    },
    {
      value: 30,
      label: '3',
    },
    {
      value: 40,
      label: '4',
    },
    {
        value: 50,
        label: '5',
      },
      {
        value: 60,
        label: '6',
      },
      {
        value: 70,
        label: '7',
      },
      {
        value: 80,
        label: '8',
      },
      {
        value: 90,
        label: '9',
      },
      {
        value: 100,
        label: '10',
      },
  ];
function Scale(props) {
    function valuetext(value) {
  

        return `${value}°C`;
      }
      
  return (
    <Box sx={{ width: '100%'}}
    style={{display:"flex" ,justifyContent:"center" ,alignItems:"center", }}
    margin={'auto'}

    >
          <span className='scale__left' style={{marginTop: 100,fontFamily: "Raleway", fontWeight: 400  }}>I guess I’m okay with <br/> distractions
</span>  
      <Slider
        aria-label="Temperature"
        defaultValue={30}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={10}
        marks={marks}
        min={10}
        max={100}
        style={{justifyContent: 'center'}}
   
      />
    
         <span className='scale__right'  style={{marginTop: 100,fontFamily: "Raleway", fontWeight: 400  }} >I need such
app badly</span>
    </Box>
  )
}

export default Scale