// firebaseUtils.js
import firebase from "firebase";

const storeAnswerInFirebase = (data, userId) => {
  // Reference to the "quizData" object in Firebase Realtime Database
  const quizDataRef = firebase.database().ref("quizData/work/" + userId);

  // Push the data into the "quizData" object under the specific user ID
  quizDataRef.push(
    {
      data: data,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    },
    (error) => {
      if (error) {
        console.error("Error storing answer in Firebase:");
      } else {
        console.log("Answer stored in Firebase");
      }
    }
  );
};

export default storeAnswerInFirebase;
